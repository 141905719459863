<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="CHM_DANGER_TYPE_CD"
            label="구분"
            itemText="codeName"
            itemValue="code"
            name="chmDangerTypeCd"
            type="search"
            v-model="searchParam.chmDangerTypeCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="위험물저장소 점검항목 목록"
      tableId="table"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :columnSetting="false"
      :editable="editable"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLADD" :showLoading="false" icon="add" @btnClicked="add" />
          <c-btn
            v-if="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="grid.data"
            mappingType="POST"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveData"
            @btnCallback="saveCallback" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>
<script>
import { uid } from "quasar";
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'dangerStorageCheckItem',
  data() {
    return {
      searchParam: {
        chmDangerTypeCd: null,
        useFlag: 'Y'
      },
      grid: {
        columns: [
          {
            name: 'chmDangerTypeCd',
            field: 'chmDangerTypeCd',
            codeGroupCd: 'CHM_DANGER_TYPE_CD',
            required: true,
            label: '구분',
            align: 'center',
            style: 'width: 110px',
            sortable: true,
            type: 'select',
          },
          {
            name: 'dangerCheckItem',
            field: 'dangerCheckItem',
            required: true,
            label: '점검항목',
            align: 'left',
            style: 'width: 300px',
            sortable: true,
            type: 'text',
          },
          {
            name: 'dangerCheckContent',
            field: 'dangerCheckContent',
            required: true,
            label: '점검내용',
            align: 'left',
            style: 'width: 300px',
            sortable: true,
            type: 'text',
          },
          {
            name: 'dangerCheckMethod',
            field: 'dangerCheckMethod',
            label: '점검방법',
            align: 'left',
            style: 'width: 100px',
            sortable: true,
            type: 'text',
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width: 50px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: true,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'right',
            style: 'width: 50px',
            sortable: true,
            type: 'number',
          },
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      isSave: false,
      listUrl: '',
      saveType: '',
      saveUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.danger.masterItems.list.url;
      this.saveUrl = transactionConfig.chm.danger.masterItem.save.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      this.grid.data.splice(0, 0, {
        chmDangerStorageCheckItemId: uid(),
        chmDangerTypeCd: null,
        dangerCheckItem: '',  // 항목
        dangerCheckContent: '', // 내용
        dangerCheckMethod: '',  // 방법
        useFlag: 'Y',
        sortOrder: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      });
    },
    saveData() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?  
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
